//import { isNotNullAndUndefined } from "./utils";

import { Browser } from "@syncfusion/ej2-base";
import { ConvertToElementID, IsNullOrWhiteSpace } from "./utils";



export enum SkytrustHosts {
    dev = 'dev.secure.skytrust.co',
    beta = 'beta.secure.skytrust.co',
    preprod = 'preprod.secure.skytrust.co',
    prod = 'secure.skytrust.co',
    localhost = 'localhost'
}

export class Constants {
    //static baseAPIUrl = (process.env.REACT_APP_BASE_API_URL !== null && process.env.REACT_APP_BASE_API_URL !== undefined) ? process.env.REACT_APP_BASE_API_URL : 'http://localhost:5001/';
    static baseAPIUrl: string;
    static setBaseAPIUrl() {
        if (window.location.hostname === SkytrustHosts.dev) {
            Constants.baseAPIUrl = 'https://dev-api-secure.skytrust.co/';
        }
        else if (window.location.hostname === SkytrustHosts.beta) {
            Constants.baseAPIUrl = 'https://beta-api-secure.skytrust.co/';
        }
        else if (window.location.hostname === SkytrustHosts.preprod) {
            Constants.baseAPIUrl = 'https://preprod-api-secure.skytrust.co/';
        }
        else if (window.location.hostname === SkytrustHosts.prod) {
            Constants.baseAPIUrl = 'https://api-secure.skytrust.co/';
        }
        else if (window.location.hostname === SkytrustHosts.localhost) {
            //Constants.baseAPIUrl = 'https://beta-api-secure.skytrust.co/';
            Constants.baseAPIUrl = 'https://dev-api-secure.skytrust.co/';
        }
        else {
            Constants.baseAPIUrl = 'https://localhost:7190/';
        }
    }


    static cookieName = 'ACSESSIONC';
    static HorizontalRAFFormElement = 'HorizontalRAFFormElement';
    static VerticalRAFFormElement = 'VerticalRAFFormElement';
    static DropdownFetchCount = 50;
    static ListViewFetchCount = 20;
    //static MaxDate = new Date(Date.UTC(9998, 11, 31));
    static MaxDate = new Date(Date.UTC(9999, 11, 31));
    //static MinDate = new Date(Date.UTC(1970, 1, 1));
    static MinDate = new Date(Date.UTC(1, 0, 1));
    //static MaxDate = new Date(9999, 11, 31);
    static RAFObject_NoContent = 'NoContent';
    static MyOrganizationPortal = 'my organization';
    static idSuffix = '0-0_0';
}

// Call the method to set the baseAPIUrl
Constants.setBaseAPIUrl();

export class ContentType {
    static applicationFormUrlEncoded = 'application/x-www-form-urlencoded';
    static applicationJson = 'application/json';
}
export class RAFDatePickerViewFormat {
    static DATE = 'dd/MM/yyyy';
    static DATETIME = 'dd/MM/yyyy h:mm a';
}
export class MomentFormats {
    static DATE = 'DD/MM/YYYY';
    static DATETIME = 'DD/MM/YYYY h:mm a';
}
export enum RAFLayout {
    OneColumnLayout = 1,
    TwoColumnLayout = 2,
    ThreeColumnLayout = 3,
    FourColumnLayout = 4
}

export class StorageKey {
    static MenuModel = 'MenuModel';
    static Terminologies = 'Terminologies';
    static allViews_modulename = '_allViews_';
    static adminViews_modulename = '_adminViews_';
    static allReportView_modulename = '_allReportView_';
    static allDashboardView_modulename = '_allDashboardView_';
    static recentViewId_modulename = '_recentViewId_';
    static clterms_modulename = '_clterms_';
    static viewAttribs_modulename = '_viewAttribs_';
    static allAttribs_modulename = '_allAttribs_';
    static rafgrid_viewId = 'rafgrid_';
    static currentBusinessUnit = 'currentBusinessUnit';
    static currentUser = 'currentUser';
    static all_businessUnit = 'all_businessUnit';
    static formAttributes_formType = 'formAttributes_';
    static templatFormAttributes__formType = 'templatFormAttributes_';
    static userDefinition = 'userDefinition';
    static returnUrl = 'returnurl';
    static allUsers = '_allUsers';
    static allUserGroups = '_allUserGroups';
    static myUserGroups = '_myUserGroups';
    static recentViewId_admin_modulename = '_recentViewId_admin_';
    static all_Permissions = '_all_Permissions';
    static currentTenant = 'currentTenant';
    //static allForms = 'allForms';
    static documentFolderArray = '_documentFolderArray_';
    static selectedBPTypeItemUID = '_selectedBPTypeItemUID_';
    static simpleEntity_modulename = '_SimpleEntity_';
    static entity_modulename = '_Entity_';
    static objPageLayout_UID = '_objPageLayout_';
    static pageLayoutList_pageTypeentity = '_pageLayoutList_';
    static PortalList = '_portalList';
    static CurrentPortal = '_currentPortal';
}

export enum RAFViewVisiblity {
    IsPrivate = 'Visible only to me',
    IsPublic = 'Shared with others',
}

export enum activitySectionType {
    Timeline = "Timeline",
    Notes = "Notes",
    Tasks = "Tasks",
}
export enum SFColumnType { //syncfusion ColumnDirective 
    string = "string",
    number = "number",
    boolean = "boolean",
    date = "date",
    datetime = "datetime",
    dropdown = "dropdown", // there is no type dropdown in syncfusion ColumnDirective.. this SFColumnType used only for filterTemplate
    lookup = "lookup", // there is no type dropdown in syncfusion ColumnDirective.. this SFColumnType used only for filterTemplate
}

export enum RAFInputType {
    Text = 'Text',
    Number = 'Numeric',
    DropDownList = 'DropDownList',
    LookupDropdownList = 'LookupDropdownList',
    Date = 'Date',
    AutoComplete = 'AutoComplete',
    Boolean = 'Boolean',
}
export enum RAFInputComponentType {
    TextBox = 'Text Box',
    TextArea = 'Text Area',
    DatePicker = 'Date Picker',
    Dropdown = 'Dropdown',
    MultiSelect = 'MultiSelect'
}

export class RAFDatePresets {
    //day
    static Yesterday = "yesterday";
    static Today = "today";
    static Tomorrow = "tomorrow";
    static TillYesterday = "tillyesterday";
    static TillToday = "tilltoday";
    static TillNow = "tillnow";
    static Last7Days = "last7";
    static Last15Days = "last15";
    static Last30Days = "last30";
    static Last45Days = "last45";
    static Last60Days = "last60";
    static Last90Days = "last90";
    static Last120Days = "last120";
    static Last180Days = "last180";
    static Next7Days = "next7";
    static Next15Days = "next15";
    static Next30Days = "next30";
    static Next45Days = "next45";
    static Next60Days = "next60";
    static Next90Days = "next90";
    static Next120Days = "next120";
    //Calendar Week
    static LastWeek = "lastweek";
    static ThisWeek = "thisweek";
    static NextWeek = "nextweek";
    //Calendar Month
    static LastMonth = "lastmonth";
    static ThisMonth = "thismonth";
    static NextMonth = "nextmonth";
    static CurrentAndPreviousMonth = "lastthismonth";
    static CurrentAndNextMonth = "thisnextmonth";
    //Calendar Year
    static CurrentCalendarYear = "cury";
    static PreviousCalendarYear = "prevy";
    static Previous2CalendarYear = "prev2y";
    static Ago2CalendarYear = "ago2y";
    static NextCalendarYear = "nexty";
    static CurrentAndPreviousCalendarYear = "prevcury";
    static CurrentAndPrevious2CalendarYear = "prevcur2y";
    static CurrentAndNextCalendarYear = "curnexty";
    //Calendar Quarter
    static CurrentCalendarQuarter = "currentq";
    static CurrentAndNextCalendarQuarter = "curnextq";
    static CurrentAndPreviousCalendarQuarter = "curprevq";
    static NextCalendarQuarter = "nextq";
    static PreviousCalendarQuarter = "prevq";
    static CurrentAndNext3CalendarQuarter = "curnext3q";

}

export class RAFHeaderNames {
    static Authorization = 'Authorization';
    static BusinessUnitUID = 'buuid';
    static Domain = 'SubDomain';
}


export enum RAFActionStatus {
    Scheduled = 'Scheduled',
    Planned = 'Planned',
    Accepted = 'Accepted',
    Completed = 'Completed',
    Deferred = 'Deferred',
    Postponed = 'Postponed',
    Approved = "Approved",
    Rejected = "Rejected",
    Inprocess = "Inprocess",
    Done = 'Done',
    NotDone = 'Not done',
    Refused = 'Refused',
    NoTime = 'No time',
    Pending = 'Pending',
    Paid = 'Paid',
    Ready = "Ready",
    Invoiced = 'Invoiced',
    InvoiceSent = 'Invoice Sent',
    PaymentReceived = 'Payment Received',
    NotReady = 'Not Ready',
    Unassigned = 'Unassigned',
    InProgress = 'In Progress',
    Active = 'Active',
    Resolved = 'Resolved',
    Taken = 'Taken',
    PartiallyTaken = 'Partially taken',
    NotTaken = 'Not taken',
    Submitted = 'Submitted',
    Reported = 'Reported',
    OfferSent = 'Offer Sent',
    Acknowledged = 'Acknowledged'
}


export class RAFStatusNameWithColor {
    static Planned = { Value: 'Planned', DisplayName: 'Planned', Color: '#0078d6', LightColor: '#ADD8E6' };
    static Scheduled = { Value: 'Scheduled', DisplayName: 'Scheduled', Color: '#0078d6', LightColor: '#ADD8E6' };
    static Accepted = { Value: 'Accepted', DisplayName: 'Accepted', Color: '#2ecc71', LightColor: '#98FB98' };
    static Completed = { Value: 'Completed', DisplayName: 'Completed', Color: '#27ae60', LightColor: '#90EE90' };
    static Submitted = { Value: 'Submitted', DisplayName: 'Submitted', Color: '#0078d6', LightColor: '#ADD8E6' };
    static Uploaded = { Value: 'Uploaded', DisplayName: 'Uploaded', Color: '#27ae60', LightColor: '#90EE90' };
    static Resolved = { Value: 'Resolved', DisplayName: 'Resolved', Color: '#27ae60', LightColor: '#90EE90' };
    static Done = { Value: 'Done', DisplayName: 'Done', Color: '#27ae60', LightColor: '#90EE90' };
    static Approved = { Value: 'Approved', DisplayName: 'Approved', Color: '#9b59b6', LightColor: '#D3D3D3' };
    static Rejected = { Value: 'Rejected', DisplayName: 'Rejected', Color: '#8B0000', LightColor: '#FFC0CB' };
    static Cancelled = { Value: 'Cancelled', DisplayName: 'Cancelled', Color: '#8B0000', LightColor: '#FFC0CB' };
    static Inprocess = { Value: 'Inprocess', DisplayName: 'In Progress', Color: '#f39c12', LightColor: '#FFD700' };
    static InProgress = { Value: 'InProgress', DisplayName: 'In Progress', Color: '#f39c12', LightColor: '#FFD700' };
    static "Not Uploaded" = { Value: 'NotUploaded', DisplayName: 'Not Uploaded', Color: '#F8A830', LightColor: '#F8A830' };
    static 'In Progress' = { Value: 'Inprocess', DisplayName: 'In Progress', Color: '#f39c12', LightColor: '#FFD700' };
    static Paid = { Value: 'Paid', DisplayName: 'Paid', Color: '#0078d6', LightColor: '#ADD8E6' };
    static 'Not Ready' = { Value: 'Not Ready', DisplayName: 'Not Ready', Color: '#f39c12', LightColor: '#FFD700' };
    static 'Ready to Process' = { Value: 'Ready to Process', DisplayName: 'Ready to Process', Color: '#0078d6', LightColor: '#ADD8E6' };
    static Invoiced = { Value: 'Invoiced', DisplayName: 'Invoiced', Color: '#e74c3c', LightColor: '#FFA07A' };
    static 'Invoice Sent' = { Value: 'Invoice Sent', DisplayName: 'Invoice Sent', Color: '#2ecc71', LightColor: '#98FB98' };
    static 'Payment Received' = { Value: 'Payment Received', DisplayName: 'Payment Received', Color: '#9b59b6', LightColor: '#D3D3D3' };
    static 'Not done' = { Value: 'Not done', DisplayName: 'Not done', Color: '#f9a825', LightColor: '#D3D3D3' };
    static 'No time' = { Value: 'No time', DisplayName: 'No time', Color: '#666666', LightColor: '#D3D3D3' };
    static Refused = { Value: 'Refused', DisplayName: 'Refused', Color: '#b71c1c', LightColor: '#FFA07A' };
    static Unassigned = { Value: 'Unassigned', DisplayName: 'Unassigned', Color: '#e74c3c', LightColor: '#FFA07A' };
    static Published = { Value: 'Published', DisplayName: 'Published', Color: '#0078d6', LightColor: '#FFA07A' };
    static Draft = { Value: 'Draft', DisplayName: 'Draft', Color: '#666666', LightColor: '#FFA07A' };
    static Pending = { Value: 'Pending', DisplayName: 'Pending', Color: '#5B39F0', LightColor: '#DED7FC' };
    static 'Awaiting Approval' = { Value: 'Awaiting Approval', DisplayName: 'Awaiting Approval', Color: '#5B39F0', LightColor: '#DED7FC' };
    static 'Notes Missing' = { Value: 'Notes Missing', DisplayName: 'Notes Missing', Color: '#b71c1c', LightColor: '#FFA07A' };
    static 'Minimal' = { Value: 'Minimal', DisplayName: 'Minimal', Color: '#666666', LightColor: '#FFA07A' };
    static 'Short' = { Value: 'Short', DisplayName: 'Short', Color: '#5B39F0', LightColor: '#DED7FC' };
    static 'Moderate' = { Value: 'Moderate', DisplayName: 'Moderate', Color: '#f39c12', LightColor: '#FFD700' };
    static 'Detailed' = { Value: 'Detailed', DisplayName: 'Detailed', Color: '#0078d6', LightColor: '#ADD8E6' };
    static 'Elaborate' = { Value: 'Elaborate', DisplayName: 'Elaborate', Color: '#2ecc71', LightColor: '#98FB98' };
    static 'Xero Sync Success' = { Value: 'Xero Sync Success', DisplayName: 'Xero Sync Success', Color: '#2ecc71', LightColor: '#98FB98' };
    static 'Xero Sync Failed' = { Value: 'Xero Sync Failed', DisplayName: 'Xero Sync Failed', Color: '#e74c3c', LightColor: '#FFA07A' };
    static 'Added to Invoice' = { Value: 'Added to Invoice', DisplayName: 'Added to Invoice', Color: '#e74c3c', LightColor: '#FFA07A' };
    static 'Invoiced Directly' = { Value: 'Invoiced Directly', DisplayName: 'Invoiced Directly', Color: '#2ecc71', LightColor: '#98FB98' };
    static 'Invoiced to Xero' = { Value: 'Invoiced to Xero', DisplayName: 'Invoiced to Xero', Color: '#9b59b6', LightColor: '#D3D3D3' };
}

export class RAFGenderConstant {
    static male = { value: 'male', DisplayName: 'Male', iconCss: 'far fa-mars', iconCss_l: 'fal fa-mars' };
    static female = { value: 'female', DisplayName: 'Female', iconCss: 'far fa-venus', iconCss_l: 'fal fa-venus' };
    static non_binary = { value: 'non_binary', DisplayName: 'Non-binary', iconCss: 'far fa-transgender', iconCss_l: 'fal fa-transgender' };
    static other = { value: 'other', DisplayName: 'Non-binary', iconCss: 'far fa-transgender', iconCss_l: 'fal fa-transgender' };
}

export enum RAFTaskType {
    ToDo = 'To-Do',
    Review = 'Review',
    Approval = 'Approval',
    Action = 'Action',
    Event = 'Event',
    //Shift = 'Shift',
    Appointment = 'Appointment',
}

export enum RAFEmailStatus {
    Sent = 'Sent',
    Received = 'Received',
    Queued = 'Queued',
    Saved = 'Saved',
    Error = 'Error'
}

export enum Action {
    Added = 1,
    Changed = 2,
    Deleted = 3,
    Joined = 4,
    Left = 5
}

export enum RAFLoggedInUser {
    UserId = 'a435b10d-f558-4d60-9457-9f0f36c8c281',
    Username = 'Aaron',
}

export enum RAFActions {
    Next = 'Next',
    Upcoming = 'Upcoming',
    Completed = 'Completed',
    All = 'All',
}

export class SystemVariable {
    static CURRENTUSER = "$CurrentUser";
    static CURRENTUSERGUID = "$CurrentUserGuid";
    static CURRENTUSER_DISPLAYNAME = "@Me";
}

export enum RAFModuleName {
    Account = 'Account',
    Contact = 'Contact',
    Deal = 'Deal',
    Ticket = 'Ticket',
    ContentLibrary = 'Content Library',
    Documents = 'Documents',
    EmailAttachment = 'Email Attachment',
    Email = 'Email',
    Lead = 'Lead',
    User = 'User',
    Complaint = 'Complaint',
    BusinessProcessStep = 'Business Process Step',
    BusinessProcess = 'Business Process',
    Task = "Task",
}

export enum RAFEntityName {
    Account = 'account',
    Contact = 'contact',
    Deal = 'deal',
    Ticket = 'ticket',
    Complaint = 'complaint',
    Task = 'task',
    BusinessProcess = 'business_process',
    BusinessProcessStep = 'business_process_step',
    BusinessProcessTemplate = 'business_process_template',
    //BusinessProcessType = 'business_process_type',
    BusinessProcessType = 'category',
    Category = 'category',
    BusinessProcessStepTemplate = 'business_process_step_template',
    BusinessProcessStage = 'business_process_stage',
    BusinessRule = 'businessrule',
    User = 'user',
    Role = 'role',
    ContentLibrary = 'content_library',
    VersionHistory = 'version_history',
    Email = 'email',
    Team = 'team',
    UserSignature = 'user_signature',
    Contract = 'contract',
    FormLibrary = 'form_library',
    Entity = 'entity',
    Template = 'template',
    Portal = 'portal',
    Area = 'area',
    PortalItem = 'portal_item',
    RecordCategory = 'record_category',
    City = 'city',
    State = 'state',
    Country = 'country',
    Note = 'note',
    Document = 'document',
    DataList = 'datalist',
    FormData = 'form_data',
    Report = 'report',
    Dashboard = 'dashboard',
    View = 'view',
    BusinessRuleTrigger = 'business_rule_trigger',
    PageLayout = 'pagelayout',
    UserFocus = 'user_focus',
    DealStage = 'deal_stage',
    Department = 'department',
    Location = 'location',
    Product = 'product',
    Suggestion = 'suggestion',
    Import = 'import',
    TaskTitle = 'task_title',
    ExtraInfoJson = 'extra_info_json',
    //skyTrust
    Employee = 'employee',
    EmployeeLoginStatus = 'employee_login_status',
    Incident = 'incident',
    Tenant = 'tenant',
    Inspection = 'inspection',
    InspectionLibrary = 'inspection_library',
    InductionLibrary = 'induction_library',
    PermitLibrary = 'permit_library',
    SignInRegister = 'sign_in_register',
    InspectionLibraryCategoryType = 'inspection_library_categorytype', //for internal purpose only
    InspectionSchedule = 'inspection_schedule',
    ProcessLibrary = 'process_library',
    Induction = 'induction',
    Tag = 'tag',
    Migrate = 'migrate',//for internal purpose only
    AcknowledgementTracking = 'acknowledgement_tracking',
    InspectionData = 'inspection_data',
    ProcessData = 'process_data',
    StandardizedLibrary = 'standardized_library',

}

export function getURLPrefixByModuleName(moduleName: string, defaultValue?: string, entityUID?: string, entityName?: string) {
    let outVal = moduleName;
    if (!IsNullOrWhiteSpace(moduleName)) {
        switch (moduleName) {
            case RAFEntityName.Account:
                outVal = "Account";
                break;
            case RAFEntityName.Contact:
                outVal = "Contact";
                break;
            case RAFEntityName.Deal:
                outVal = "Deal";
                break;
            case RAFEntityName.Ticket:
                outVal = "Ticket";
                break;
            case RAFEntityName.Complaint:
                outVal = "Complaint";
                break;
            case RAFEntityName.Task:
                outVal = "Task";
                break;
            case RAFEntityName.Employee:
                outVal = "Employee";
                break;
            case RAFEntityName.User:
                outVal = "User";
                break;
            case RAFEntityName.EmployeeLoginStatus:
                outVal = "Employee";
                break;
            case RAFEntityName.Incident:
                outVal = "Incident";
                break;
            case RAFEntityName.Tenant:
                outVal = "Tenant";
                break;
            case RAFEntityName.Portal:
                outVal = "Portal";
                break;
            case RAFEntityName.Import:
                outVal = "Import";
                break;
            case RAFEntityName.Role:
                outVal = "PermissionGroup";
                break;
            case RAFEntityName.FormLibrary:
                outVal = "FormLibrary";
                break;
            case RAFEntityName.Entity:
                outVal = "Entity";
                break;
            case RAFEntityName.InspectionLibrary:
                outVal = "InspectionLibrary";
                break;
            case RAFEntityName.Location:
                outVal = "Location";
                break;
            case RAFEntityName.Department:
                outVal = "Department";
                break;
            case RAFEntityName.Area:
                outVal = "Area";
                break;
            case RAFEntityName.InspectionSchedule:
                outVal = "InspectionSchedule";
                break;
            case RAFEntityName.ContentLibrary:
                outVal = "ContentLibrary";
                break;
            case RAFEntityName.AcknowledgementTracking:
                outVal = "AcknowledgementTracking";
                break;
            case RAFEntityName.StandardizedLibrary:
                outVal = "StandardizedLibrary";
                break;
            default:
                //outVal = ConvertToURLValue(moduleName);
                break;
        }
    }


    outVal = !IsNullOrWhiteSpace(defaultValue) ? defaultValue : (!IsNullOrWhiteSpace(entityUID) || !IsNullOrWhiteSpace(entityName)) ? 'DataList' : outVal;
    return outVal;
};

export enum RAFAttributeName {
    UID = 'uid',
    RecordInfo = 'record_info',
    //Category = 'category',
    Entity = 'entity',
    FormLibrary = 'form_library',
    TagsListJson = 'tags_list_json',
    TagsListJsonText = 'tags_list_json_text',
    Portal = 'portal',
    BusinessProcess = 'business_process',
    ProcessStatus = 'process_status',

}

export enum RAFModuleURLName {
    Account = 'Company',
    Contact = 'Contact',
    Deal = 'Deal',
    Ticket = 'Ticket',
}

export enum RAFViewType {
    Lookup = "lookup",
    Default = "default",
    RelatedTo = "relatedto",
    Report = "report",
    Group = "group",
    SharedView = "SharedView",
    SystemView = "SystemView",
    MyView = "MyView",
}

export enum RAFReportType {
    Folder = 'Folder',
    Pivot = 'Pivot',
    Chart = 'Chart',
    Analytic = 'Analytic',
    Table = 'Table',
    Group = 'Group',
    Dashboard = 'Dashboard',
    List = 'List',
    Metric = 'Metric',
    Default = 'default',
    Tags = 'Tags',
}

export enum RAFViewPermission {
    SharedView = "SharedView",
    SystemView = "SystemView",
    MyView = "MyView",
}

export enum RAFReportTypeDisplayName {
    Visualization = 'Visualization',
    Analytic = 'Analytics',
    Table = 'Templates',
    Dashboard = 'Dashboards',
    List = 'List',
    View = 'Views',
    Record = 'Processes',
    Tags = 'Tags',
}

export enum RAFViewTypeDisplayName {
    SharedView = "Shared View",
    SystemView = "System View",
    MyView = "My View",
}

export enum RAFReportViewType {
    Table = 'Table',
    Metric = 'Metric',
    Group = 'Group',
    Pivot = 'Pivot',
    Chart = 'Chart',
}

export enum RAFChartType {
    VerticalBar = 'Vertical Bar',
    VerticalBarStacked = 'Vertical Bar - Stacked',
    VerticalBarStacked100 = 'Vertical Bar - Stacked to 100%',
    Line = 'Line',
    Pie = 'Pie',
    Doughnut = 'Doughnut',
    Funnel = 'Funnel',
    Bar = 'Bar',
    Table = 'Table',
    Column = 'Column',
}

export class RAFTreeViewMenu {
    UID?: string;
    DisplayName?: string;
    Name?: string;
    Category?: string;
    ParentID?: string;
    IsPrivate?: boolean;
    IsSystem?: boolean;
    ViewType?: string;
    IsDefault?: boolean;
    PermissionName?: string;
    //Permission?: string;
    //ChartType?: string;
}

export enum RAFVisualType {
    Table = 'Table',
    Metric = 'Metric',
    Chart = 'Chart',
}

export enum RAFAggregeateFunction {
    Count = 'count',
    Sum = 'sum',
    Avg = 'avg',
    Max = 'max',
    Min = 'min'
}

export enum RAFDateFunction {
    Week = "week",
    Month = "month",
    MonthName = "month name",
    MonthNameYear = "month name year",
    Year = "year",
    Day = "day",
    DayName = "day name",
    Age = "age",
    Quarter = "quarter"
}

export enum RAFDocumentType {
    File = "File",
    Folder = "Folder",
    Page = "Page", //Page = "WebDoc",
    Collection = "Collection",
}

export enum RAFDocumentTypeDisplayname {
    File = 'File',// "Uploaded File",
    Folder = "Folder",
    Page = 'Page', // Page = 'Page'// "Web Doc",
    Collection = "Collection",
}


export class RAFDocumentTypeName {
    static documentTypeConstant(Id: string, DisplayName: string, CollectionName: string, IconClass: string) {
        return { Id, DisplayName, CollectionName, IconClass };
    }

    static File = RAFDocumentTypeName.documentTypeConstant('File', 'File', 'Files', 'fiv-viv-lg fiv-viv fiv-icon-blank fiv-icon-');
    static Folder = RAFDocumentTypeName.documentTypeConstant('Folder', 'Folder', 'Folders', "fas fa-folder-open content_state_warning_base");
    static Page = RAFDocumentTypeName.documentTypeConstant('Page', 'Page', 'Pages', 'far fa-file-invoice content_brand_tertiary_base');
    static Collection = RAFDocumentTypeName.documentTypeConstant('Collection', 'Collection', 'Collections', 'fas fa-books content_brand_primary_base');
}

export enum RAFBusinessAction {
    setValue = "setValue",
    clearValue = "clearValue",
    showField = "showField",
    showStep = "showStep",
    hideField = "hideField",
    hideStep = "hideStep",
    makeRequired = "makeRequired",
    showMessage = "showMessage",
    setCurrentDateAs = "setCurrentDateAs",
    setCurrentUserAs = "setCurrentUserAs",
    copyValue = "copyValue",
    sendMail = "sendMail",
    sendSms = "sendSms",
    sendReport = "sendReport",
    createRecord = "createRecord",
    linkEmailToDeal = "linkEmailToDeal",
    createTicket = "createTicket",
}

export enum RAFUserTeam {
    User = "User",
    Team = "Team",
}

export enum InputFieldColclassname {
    HalfWidth = 'col-md-7 col-xl-7 col-sm-9 col-xxl-5',
    FullWidth = 'col-md-7 col-xl-9 col-sm-9',
}

export class RAFButtonConstant {
    static buttonConstant(Id: string, DisplayName: string, IconCss: string) {
        return { Id, DisplayName, IconCss };
    }

    static Save = RAFButtonConstant.buttonConstant('Save', 'Save', 'fas fa-floppy-disk');
    static Submit = RAFButtonConstant.buttonConstant('Submit', 'Submit', 'fas fa-floppy-disk');
    static SaveAddNew = RAFButtonConstant.buttonConstant('SaveAddNew', 'Save & Add New', 'fas fa-floppy-disk');
    static SaveAsDraft = RAFButtonConstant.buttonConstant('SaveAsDraft', 'Save as Draft', 'fas fa-floppy-disk');
    static SaveAndNext = RAFButtonConstant.buttonConstant('SaveAndNext', 'Save & Next', 'fas fa-floppy-disk');
    static SaveAndClose = RAFButtonConstant.buttonConstant('SaveAndNext', 'Save & Close', 'fas fa-floppy-disk');
    static Create = RAFButtonConstant.buttonConstant('Create', 'Create', 'fas fa-plus');
    static Attach = RAFButtonConstant.buttonConstant('Attach', 'Attach', 'fas fa-paperclip');
    static Edit = RAFButtonConstant.buttonConstant('Edit', 'Edit', 'fas fa-pen-to-square');
    static Details = RAFButtonConstant.buttonConstant('Details', 'Details', 'fas fa-pen-to-square');
    static Resolve = RAFButtonConstant.buttonConstant('Resolve', 'Resolve', RAFButtonConstant.Edit.IconCss);
    static Revise = RAFButtonConstant.buttonConstant('Revise', 'Revise', RAFButtonConstant.Edit.IconCss);
    static MarkAsRead = RAFButtonConstant.buttonConstant('MarkAsRead', 'Mark as Read', 'fas fa-check-double');
    static MarkAsUnRead = RAFButtonConstant.buttonConstant('MarkAsUnRead', 'Mark as Unread', 'fas fa-circle');
    static Merge = RAFButtonConstant.buttonConstant('Merge', 'Merge', 'fas fa-merge');
    static Ok = RAFButtonConstant.buttonConstant('Ok', 'Ok', 'far fa-check');
    static Close = RAFButtonConstant.buttonConstant('Close', 'Close', 'fal fa-xmark');
    static Cancel = RAFButtonConstant.buttonConstant('Cancel', 'Cancel', 'fal fa-xmark');
    static Settings = RAFButtonConstant.buttonConstant('Settings', 'Settings', 'fas fa-gear');
    static Copy = RAFButtonConstant.buttonConstant('Copy', 'Copy', 'far fa-copy');
    static Preview = RAFButtonConstant.buttonConstant('Preview', 'Preview', 'fas fa-eye');
    static View = RAFButtonConstant.buttonConstant('View', 'View', 'fas fa-arrow-right-long');
    static Complete = RAFButtonConstant.buttonConstant('Complete', 'Complete', 'far fa-check');
    static EditDashboard = RAFButtonConstant.buttonConstant('EditDashboard', 'Edit Dashboard', 'fa fa-th-large');
    static AddDashboard = RAFButtonConstant.buttonConstant('AddDashboard', 'Add Dashboard', 'fa fa-th-large');
    static AddWidget = RAFButtonConstant.buttonConstant('AddWidget', 'Add Widget', 'fas fa-plus');
    static Expand = RAFButtonConstant.buttonConstant('Expand', 'Expand', 'fas fa-expand-alt');
    static Add = RAFButtonConstant.buttonConstant('Add', 'Add', 'fas fa-plus');
    static AddTask = RAFButtonConstant.buttonConstant('AddTask', 'Add Task', 'fas fa-plus');
    static SyncOffline = RAFButtonConstant.buttonConstant('SyncOffline', 'Sync Offline', 'fas fa-rotate');
    static MassUpdate = RAFButtonConstant.buttonConstant('MassUpdate', 'Mass Update', 'far fa-file-pen');
    static Delete = RAFButtonConstant.buttonConstant('Delete', 'Delete', 'fas fa-trash');
    static VersionHistory = RAFButtonConstant.buttonConstant('VersionHistory', 'Version History', 'fa fa-history');
    static Version = RAFButtonConstant.buttonConstant('Version', 'Version', 'fa fa-history');
    static History = RAFButtonConstant.buttonConstant('History', 'History', 'fa fa-history');
    static Timeline = RAFButtonConstant.buttonConstant('Timeline', 'Timeline', 'fa fa-list-timeline');
    static More = RAFButtonConstant.buttonConstant('More', 'More', 'fas fa-ellipsis-vertical');
    static MoreHorizontal = RAFButtonConstant.buttonConstant('More', 'More', 'fas fa-ellipsis');
    static AllComments = RAFButtonConstant.buttonConstant('AllComments', 'All Comments', 'fal fa-comment-dots');
    static Switch = RAFButtonConstant.buttonConstant('Switch', 'Switch', 'fal fa-shuffle');
    static DialogClose = RAFButtonConstant.buttonConstant('DialogClose', 'DialogClose', 'fas fa-xmark');
    static ArrowBack = RAFButtonConstant.buttonConstant('Back', 'Back', 'fas fa-arrow-left-long');
    static Back = RAFButtonConstant.buttonConstant('Back', 'Back', 'fas fa-chevron-left');
    static Previous = RAFButtonConstant.buttonConstant('Previous', 'Previous', 'fas fa-chevron-left');
    static Next = RAFButtonConstant.buttonConstant('Next', 'Next', 'fas fa-chevron-right');
    static Download = RAFButtonConstant.buttonConstant('Download', 'Download', 'fa fa-cloud-download-alt');
    static Import = RAFButtonConstant.buttonConstant('Import', 'Import', 'fas fa-upload');
    static Export = RAFButtonConstant.buttonConstant('Export', 'Export', 'fas fa-download');
    static Search = RAFButtonConstant.buttonConstant('Search', 'Search', 'fas fa-magnifying-glass');
    static Assign = RAFButtonConstant.buttonConstant('Assign', 'Assign', 'fas fa-user');
    static Sort = RAFButtonConstant.buttonConstant('Sort', 'Sort', 'fas fa-sort');
    static Filter = RAFButtonConstant.buttonConstant('Filter', 'Filter', 'far fa-filter');
    static ShowFilter = RAFButtonConstant.buttonConstant('ShowFilter', 'Show Filter', 'far fa-filter');
    static HideFilter = RAFButtonConstant.buttonConstant('HideFilter', 'Hide Filter', 'far fa-filter-slash');
    static Filters = RAFButtonConstant.buttonConstant('Filters', 'Filters', RAFButtonConstant.Filter.IconCss);
    static Select = RAFButtonConstant.buttonConstant('Select', 'Select', 'fal fa-square-check');
    static SelectAll = RAFButtonConstant.buttonConstant('SelectAll', 'Select All', 'fas fa-check-double');
    static UnSelect = RAFButtonConstant.buttonConstant('UnSelectAll', 'Unselect All', 'far fa-square');
    static AIAssistant = RAFButtonConstant.buttonConstant('AIAssistant', 'Ask FRED', 'far fa-wand-magic-sparkles');
    static Apply = RAFButtonConstant.buttonConstant('Apply', 'Apply', RAFButtonConstant.Filter.IconCss);
    static Generate = RAFButtonConstant.buttonConstant('Generate', 'Generate', RAFButtonConstant.Filter.IconCss);
    static ShowResults = RAFButtonConstant.buttonConstant('ShowResults', 'Show result(s)', RAFButtonConstant.Filter.IconCss);
    static AddCondition = RAFButtonConstant.buttonConstant('AddCondition', 'Add Condition', RAFButtonConstant.Add.IconCss);
    static AllAttachments = RAFButtonConstant.buttonConstant('AllAttachments', 'All Attachments', RAFButtonConstant.Attach.IconCss);
    static AddAction = RAFButtonConstant.buttonConstant('AddAction', 'Add Action', RAFButtonConstant.Add.IconCss);
    static AddRule = RAFButtonConstant.buttonConstant('AddRule', 'Add Rule', RAFButtonConstant.Add.IconCss);
    static Archive = RAFButtonConstant.buttonConstant('Archive', 'Archive', this.Cancel.IconCss);
    static Actions = RAFButtonConstant.buttonConstant('Actions', 'Actions', 'fad fa-message-captions');
    static Configure = RAFButtonConstant.buttonConstant('Configure', 'Configure', this.Edit.IconCss);
    static AddInput = RAFButtonConstant.buttonConstant('AddInput', 'Add Input', this.Add.IconCss);
    static Clear = RAFButtonConstant.buttonConstant('Clear', 'Clear', this.Close.IconCss);
    static Reset = RAFButtonConstant.buttonConstant('Reset', 'Reset', this.Clear.IconCss);
    static AddView = RAFButtonConstant.buttonConstant('AddView', 'Add View', this.Add.IconCss);
    static ShowSharedLibraries = RAFButtonConstant.buttonConstant('ShowSharedLibraries', 'Show Shared Libraries', 'fas fa-eye');
    static Desinger = RAFButtonConstant.buttonConstant('Desinger', 'Desinger', this.Edit.IconCss);
    static Update = RAFButtonConstant.buttonConstant('Update', 'Update', this.Edit.IconCss);
    static MarkAsDone = RAFButtonConstant.buttonConstant('MarkAsDone', 'Mark As Done', this.Complete.IconCss);
    static RunningProcessComplete = RAFButtonConstant.buttonConstant('MarkAsCompleted', 'Mark as Completed', this.Complete.IconCss);
    static Upload = RAFButtonConstant.buttonConstant('Upload', 'Upload', 'fas fa-upload');
    static Reupload = RAFButtonConstant.buttonConstant('Re-upload', 'Re-upload', 'fa fa-history');
    static UploadFile = RAFButtonConstant.buttonConstant('UploadFile', 'Upload or Choose File', 'fas fa-upload');
    static CompleteLater = RAFButtonConstant.buttonConstant('CompleteLater', 'Complete Later', 'fass fa-alarm-snooze');
    static SaveAsTemplate = RAFButtonConstant.buttonConstant('SaveAsTemplate', 'Save As Template', this.Save.IconCss);
    static SaveAndPublish = RAFButtonConstant.buttonConstant('SaveAndPublish', 'Save & Publish', this.Upload.IconCss);
    static SwitchToDraft = RAFButtonConstant.buttonConstant('SwitchToDraft', 'Switch to draft', this.Export.IconCss);
    static Publish = RAFButtonConstant.buttonConstant('Publish', 'Publish', this.Upload.IconCss);
    static Browse = RAFButtonConstant.buttonConstant('Browse', 'Browse', 'fas fa-magnifying-glass');
    static SaveDraft = RAFButtonConstant.buttonConstant('SaveDraft', 'Save Draft', this.Save.IconCss);
    static Skip = RAFButtonConstant.buttonConstant('Skip', 'Skip', this.Save.IconCss);
    static AddRecord = RAFButtonConstant.buttonConstant('AddRecord', 'Add Record', this.Add.IconCss);
    static Clone = RAFButtonConstant.buttonConstant('Clone', 'Clone', "far fa-clone");
    static Rename = RAFButtonConstant.buttonConstant('Rename', 'Rename', "fas fa-i-cursor");
    static Start = RAFButtonConstant.buttonConstant('Start', 'Start', "fas fa-play-circle");
    static End = RAFButtonConstant.buttonConstant('End', 'End', "fas fa-play");
    static AddWorkspace = RAFButtonConstant.buttonConstant('AddWorkspace', 'Add To Portal', this.Add.IconCss);
    static ToggleSideMenu = RAFButtonConstant.buttonConstant('ToggleSideMenu', 'ToggleSideMenu', this.Add.IconCss);
    static SectionExpandBtn = RAFButtonConstant.buttonConstant('SectionExpandBtn', 'Expand', 'fas fa-expand-alt');
    static ShowMore = RAFButtonConstant.buttonConstant('ShowMore', 'Show more', 'fas fa-chevron-down');
    static HideLess = RAFButtonConstant.buttonConstant('HideLess', 'Hide less', 'fas fa-chevron-up');
    static Approve = RAFButtonConstant.buttonConstant('Approve', 'Approve', 'far fa-thumbs-up');
    static Invoice = RAFButtonConstant.buttonConstant('Invoice', 'Invoice', 'far fa-thumbs-up');
    static Reject = RAFButtonConstant.buttonConstant('Reject', 'Reject', 'far fa-thumbs-down');
    static Paid = RAFButtonConstant.buttonConstant('Paid', 'Paid', 'far fa-thumbs-up');
    static Rotate = RAFButtonConstant.buttonConstant('Rotate', 'Rotate', 'fas fa-rotate-right');
    static ZoomIn = RAFButtonConstant.buttonConstant('ZoomIn', 'Zoom In', 'fas fa-magnifying-glass-plus');
    static ZoomOut = RAFButtonConstant.buttonConstant('ZoomOut', 'Zoom Out', 'fas fa-magnifying-glass-minus');
    static ManageViews = RAFButtonConstant.buttonConstant('ManageViews', 'Manage Views', 'far fa-gallery-thumbnails');
    static ManageTableViews = RAFButtonConstant.buttonConstant('ManageTableViews', 'Table', 'e-icons e-table table-view-color');
    static ManageGroupViews = RAFButtonConstant.buttonConstant('ManageGroupViews', 'Group', 'e-icons e-date-occurring group-view-color');
    static ManagePivotViews = RAFButtonConstant.buttonConstant('ManagePivotViews', 'Pivot', 'e-icons e-chart-switch-row-column pivot-view-color');
    static ManageChartViews = RAFButtonConstant.buttonConstant('ManageChartViews', 'Chart', 'fa fa-chart-pie chart-view-color');
    static ManageDashboardViews = RAFButtonConstant.buttonConstant('ManageDashboardViews', 'Dashboard', 'fa fa-th-large dashboard-view-color');
    static ManageReportTableViews = RAFButtonConstant.buttonConstant('ManageReportTableViews', 'Report Table', 'fa fa-th-large group-view-color');
    static ManageMetricViews = RAFButtonConstant.buttonConstant('ManageMetricViews', 'Metric', 'fa fa-th-large group-view-color');
    static Unlink = RAFButtonConstant.buttonConstant('Unlink', 'Unlink', 'fa fa-unlink');
    static Sign = RAFButtonConstant.buttonConstant('Sign', 'Sign', 'far fa-file-signature');
    static AcknowledgmentRequest = RAFButtonConstant.buttonConstant('AcknowledgmentRequest', 'Request', 'fal fa-signature');

}

export enum RAFBtnContentString {
    RunningProcessComplete = "Mark as Completed",
    RunningProcessStepComplete = "Mark as Done",
    InitialForm = "Settings",
    Save = "Save",
    SaveAsDraft = "Save as Draft",
    Desinger = "Designer",
    ShowSharedLibraries = "Show Shared Libraries",
    Create = "Create",
    AddView = "Add View",
    Attach = 'Attach',
    Edit = 'Edit',
    Ok = 'OK',
    Update = 'Update',
    Close = 'Close',
    Clear = 'Clear',
    Cancel = 'Cancel',
    Settings = 'Settings',
    Copy = 'Copy',
    Preview = 'Preview',
    Complete = 'Complete',
    AddInput = 'Add Input',
    EditDashboard = 'Edit Dashboard',
    AddDashboard = 'Add Dashboard',
    AddWidget = 'Add Widget',
    Expand = 'Expand',
    Add = 'Add',
    SyncOffline = 'Sync Offline',
    MassUpdate = 'Mass Update',
    Delete = 'Delete',
    Configure = 'Configure',
    Remove = 'Remove',
}

export enum RAFBtnIdString {
    RunningProcessComplete = "MarkAsCompleted",
    RunningProcessStepComplete = "MarkAsDone",
    InitialForm = "Settings",
    Save = "Save",
    Desinger = "Designer",
    ShowSharedLibraries = "ShowSharedLibraries",
    Create = "Create",
    SaveAsDraft = "SaveAsDraft",
    AddView = "AddView",
    Attach = 'Attach',
    Edit = 'Edit',
    Ok = 'Ok',
    Close = 'Close',
    Clear = 'Clear',
    Cancel = 'Cancel',
    Settings = 'Settings',
    Copy = 'Copy',
    Preview = 'Preview',
    Complete = 'Complete',
    AddInput = 'AddInput',
    EditDashboard = 'EditDashboard',
    AddDashboard = 'AddDashboard',
    AddWidget = 'AddWidget',
    Expand = 'Expand',
    Add = 'Add',
    SyncOffline = 'SyncOffline',
    MassUpdate = 'MassUpdate',
    Delete = 'Delete',
    Configure = 'Configure',
    Update = 'Update',
    Remove = 'Remove',
}

export enum RAFBtnIconCss {
    Save = "fas fa-floppy-disk",
    SaveAsDraft = "fas fa-floppy-disk",
    Create = "fas fa-plus",
    Attach = "fas fa-paperclip",
    Edit = 'fas fa-pen-to-square',
    Ok = 'far fa-check',
    Close = 'fal fa-xmark',
    Cancel = 'fal fa-xmark',
    Settings = 'fas fa-gear',
    Copy = 'far fa-copy',
    Preview = 'fas fa-eye',
    Complete = 'far fa-check',
    Dashboard = "fa fa-th-large",
    AddWidget = 'fas fa-plus',
    Expand = 'fas fa-expand-alt',
    Add = 'fas fa-plus',
    SyncOffline = 'fas fa-rotate',
    MassUpdate = 'far fa-file-pen',
    Delete = 'fas fa-trash',
}

export const getIconCssByAction = (Action: string) => {
    let iconCss;
    if (Action === RAFBtnIdString.AddDashboard || Action === RAFBtnIdString.EditDashboard) {
        iconCss = RAFBtnIconCss.Dashboard;
    }
    else if (Action === RAFBtnIdString.AddInput ||
        Action === RAFBtnIdString.Create ||
        Action === RAFBtnIdString.AddWidget ||
        Action === RAFBtnIdString.Add) {
        iconCss = RAFBtnIconCss.Create;
    }
    else if (Action === RAFBtnIdString.AddView) {
        iconCss = RAFBtnIconCss.Create;
    }
    else if (Action === RAFBtnIdString.Attach) {
        iconCss = RAFBtnIconCss.Attach;
    }
    else if (Action === RAFBtnIdString.Cancel ||
        Action === RAFBtnIdString.Clear ||
        Action === RAFBtnIdString.Close
    ) {
        iconCss = RAFBtnIconCss.Cancel;
    }
    else if (Action === RAFBtnIdString.Complete ||
        Action === RAFBtnIdString.RunningProcessComplete ||
        Action === RAFBtnIdString.RunningProcessStepComplete) {
        iconCss = RAFBtnIconCss.Complete;
    }
    else if (Action === RAFBtnIdString.Copy) {
        iconCss = RAFBtnIconCss.Copy;
    }
    else if (Action === RAFBtnIdString.Edit) {
        iconCss = RAFBtnIconCss.Edit;
    }
    else if (Action === RAFBtnIdString.InitialForm ||
        Action === RAFBtnIdString.Settings) {
        iconCss = RAFBtnIconCss.Settings;
    }
    else if (Action === RAFBtnIdString.Ok) {
        iconCss = RAFBtnIconCss.Ok;
    }
    else if (Action === RAFBtnIdString.Preview) {
        iconCss = RAFBtnIconCss.Preview;
    }
    else if (Action === RAFBtnIdString.Save ||
        Action === RAFBtnIdString.SaveAsDraft) {
        iconCss = RAFBtnIconCss.Save;
    }
    else if (Action === RAFBtnIdString.Expand) {
        iconCss = RAFBtnIconCss.Expand;
    }
    else if (Action === RAFBtnIdString.SyncOffline) {
        iconCss = RAFBtnIconCss.SyncOffline;
    }
    else if (Action === RAFBtnIdString.MassUpdate) {
        iconCss = RAFBtnIconCss.MassUpdate;
    }
    else if (Action === RAFBtnIdString.Delete) {
        iconCss = RAFBtnIconCss.Delete;
    }
    return iconCss;
};
export const getBtnContentByAction = (Action: string) => {
    let contentString = Action;
    if (Action === RAFBtnIdString.AddDashboard) {
        contentString = RAFBtnContentString.AddDashboard;
    }
    else if (Action === RAFBtnIdString.EditDashboard) {
        contentString = RAFBtnContentString.EditDashboard;
    }
    else if (Action === RAFBtnIdString.AddInput) {
        contentString = RAFBtnContentString.AddInput;
    }
    else if (
        Action === RAFBtnIdString.Create) {
        contentString = RAFBtnContentString.Create;
    }
    else if (Action === RAFBtnIdString.AddView) {
        contentString = RAFBtnContentString.AddView;
    }
    else if (Action === RAFBtnIdString.AddWidget) {
        contentString = RAFBtnContentString.AddWidget;
    }
    else if (Action === RAFBtnIdString.Attach) {
        contentString = RAFBtnContentString.Attach;
    }
    else if (Action === RAFBtnIdString.Cancel
    ) {
        contentString = RAFBtnContentString.Cancel;
    }
    else if (
        Action === RAFBtnIdString.Clear
    ) {
        contentString = RAFBtnContentString.Clear;
    }
    else if (
        Action === RAFBtnIdString.Close
    ) {
        contentString = RAFBtnContentString.Close;
    }
    else if (Action === RAFBtnIdString.Complete) {
        contentString = RAFBtnContentString.Complete;
    }
    else if (Action === RAFBtnIdString.RunningProcessComplete) {
        contentString = RAFBtnContentString.RunningProcessComplete;
    }
    else if (Action === RAFBtnIdString.RunningProcessStepComplete) {
        contentString = RAFBtnContentString.RunningProcessStepComplete;
    }
    else if (Action === RAFBtnIdString.Copy) {
        contentString = RAFBtnContentString.Copy;
    }
    else if (Action === RAFBtnIdString.Edit) {
        contentString = RAFBtnContentString.Edit;
    }
    else if (Action === RAFBtnIdString.InitialForm) {
        contentString = RAFBtnContentString.InitialForm;
    }
    else if (Action === RAFBtnIdString.Settings) {
        contentString = RAFBtnContentString.Settings;
    }
    else if (Action === RAFBtnIdString.Ok) {
        contentString = RAFBtnContentString.Ok;
    }
    else if (Action === RAFBtnIdString.Preview) {
        contentString = RAFBtnContentString.Preview;
    }
    else if (Action === RAFBtnIdString.Save) {
        contentString = RAFBtnContentString.Save;
    }
    else if (Action === RAFBtnIdString.SaveAsDraft) {
        contentString = RAFBtnContentString.SaveAsDraft;
    }
    else if (Action === RAFBtnIdString.Add) {
        contentString = RAFBtnContentString.Add;
    }
    else if (Action === RAFBtnIdString.Expand) {
        contentString = RAFBtnContentString.Expand;
    }
    else if (Action === RAFBtnIdString.SyncOffline) {
        contentString = RAFBtnContentString.SyncOffline;
    }
    else if (Action === RAFBtnIdString.MassUpdate) {
        contentString = RAFBtnContentString.MassUpdate;
    }
    else if (Action === RAFBtnIdString.Delete) {
        contentString = RAFBtnContentString.Delete;
    }
    else if (Action === RAFBtnIdString.Configure) {
        contentString = RAFBtnContentString.Configure;
    }
    else if (Action === RAFBtnIdString.Update) {
        contentString = RAFBtnContentString.Update;
    }
    return contentString;
};
export const getBtnIdByAction = (Action: string) => {
    let idString = ConvertToElementID(Action);
    if (Action === RAFBtnIdString.AddDashboard) {
        idString = RAFBtnIdString.AddDashboard;
    }
    else if (Action === RAFBtnIdString.EditDashboard) {
        idString = RAFBtnIdString.EditDashboard;
    }
    else if (Action === RAFBtnIdString.AddInput) {
        idString = RAFBtnIdString.AddInput;
    }
    else if (
        Action === RAFBtnIdString.Create) {
        idString = RAFBtnIdString.Create;
    }
    else if (Action === RAFBtnIdString.AddView) {
        idString = RAFBtnIdString.AddView;
    }
    else if (Action === RAFBtnIdString.AddWidget) {
        idString = RAFBtnIdString.AddWidget;
    }
    else if (Action === RAFBtnIdString.Attach) {
        idString = RAFBtnIdString.Attach;
    }
    else if (Action === RAFBtnIdString.Cancel
    ) {
        idString = RAFBtnIdString.Cancel;
    }
    else if (
        Action === RAFBtnIdString.Clear
    ) {
        idString = RAFBtnIdString.Clear;
    }
    else if (
        Action === RAFBtnIdString.Close
    ) {
        idString = RAFBtnIdString.Close;
    }
    else if (Action === RAFBtnIdString.Complete) {
        idString = RAFBtnIdString.Complete;
    }
    else if (Action === RAFBtnIdString.RunningProcessComplete) {
        idString = RAFBtnIdString.RunningProcessComplete;
    }
    else if (Action === RAFBtnIdString.RunningProcessStepComplete) {
        idString = RAFBtnIdString.RunningProcessStepComplete;
    }
    else if (Action === RAFBtnIdString.Copy) {
        idString = RAFBtnIdString.Copy;
    }
    else if (Action === RAFBtnIdString.Edit) {
        idString = RAFBtnIdString.Edit;
    }
    else if (Action === RAFBtnIdString.InitialForm) {
        idString = RAFBtnIdString.InitialForm;
    }
    else if (Action === RAFBtnIdString.Settings) {
        idString = RAFBtnIdString.Settings;
    }
    else if (Action === RAFBtnIdString.Ok) {
        idString = RAFBtnIdString.Ok;
    }
    else if (Action === RAFBtnIdString.Preview) {
        idString = RAFBtnIdString.Preview;
    }
    else if (Action === RAFBtnIdString.Save) {
        idString = RAFBtnIdString.Save;
    }
    else if (Action === RAFBtnIdString.SaveAsDraft) {
        idString = RAFBtnIdString.SaveAsDraft;
    }
    else if (Action === RAFBtnIdString.Expand) {
        idString = RAFBtnIdString.Expand;
    }
    else if (Action === RAFBtnIdString.Add) {
        idString = RAFBtnIdString.Add;
    }
    else if (Action === RAFBtnIdString.SyncOffline) {
        idString = RAFBtnIdString.SyncOffline;
    }
    else if (Action === RAFBtnIdString.MassUpdate) {
        idString = RAFBtnIdString.MassUpdate;
    }
    else if (Action === RAFBtnIdString.Delete) {
        idString = RAFBtnIdString.Delete;
    }
    else if (Action === RAFBtnIdString.Configure) {
        idString = RAFBtnIdString.Configure;
    }
    else if (Action === RAFBtnIdString.Update) {
        idString = RAFBtnIdString.Update;
    }
    return idString;
};

export const rafNoViewName = 'No view found';


//export const SuperAdministrator = 'Super Administrator';
export const SecureAppAdministrator = 'Secure App Administrator';
export const SecureSuperAdministrator = 'Secure Super Administrator';

export const GuestRole = 'Guest';
export const BrowserIsDevice = Browser.isDevice; // === true not system view
export const BrowserWidth = window.innerWidth;

export class RAFFilePreSignedURL {
    EntityId?: string;
    ObjectName?: string;
    UploadURL?: string;
    DocumentType?: "File" | "Thumbnail";
    MimeType?: string;
    Error?: string;
    CustomData?: string;
    FileSize?: number;
    QuestionName?: string;
}